import React from "react";
import { Link, graphql } from "gatsby";
import { css } from "@emotion/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";

const githubIcon = <FontAwesomeIcon icon={faGithub} />;
const linkedinIcon = <FontAwesomeIcon icon={faLinkedin} />;

const pageHeader = css`
  color: "#232129";
  font-family: -apple-system, "Roboto", sans-serif, serif;
  font-size: 24px;
  margin: 12px 8px;
`;

const name = css`
  color: gray;
  align-self: center;
  font-size: xxx-large;
  text-transform: uppercase;
`;

const BREAKPOINTS = {
  xxs: "300px",
  xs: "420px",
  sm: "576px",
  md: "768px",
  lg: "900px",
  xl: "1200px",
  xxl: "1536px",
};

const container = css`
  display: flex;
  flex-flow: column;
`;

const content = css`

  subtitle {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }

  h3 {
    font-size: 16px;
    text-transform: uppercase;
    color: gray;
    display: block;
  }

  h4 {
    text-align: center;
  }

  key {
    font-size: 16px;
    font-weight: bold;
    display: block;
    margin-right: 24px;
  }

  a {
    display: inline;
    text-align: end;
    color: darkslategray;
  }

  startValue {
    font-size: 16px;
    display: inline;
  }
  
  line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  italicStartValue {
    font-size: 16px;
    display: block;
    font-style: italic;
  }

  endValue {
    font-size: 16px;
    display: block;
    text-align: end;
  }

  italicValue {
    font-size: 16px;
    font-style: italic;
    display: block;
    text-align: end;
  }

  @media (min-width: ${BREAKPOINTS.xxs}) {
    margin: 12px 5%;
  }
  @media (min-width: ${BREAKPOINTS.xs}) {
    margin: 12px 10%;
  }
  @media (min-width: ${BREAKPOINTS.md}) {
    margin: 12px 15%;
  }
  @media (min-width: ${BREAKPOINTS.lg}) {
    margin: 12px 20%;
  }
  @media (min-width: ${BREAKPOINTS.xl}) {
    margin: 12px 25%;
  }
`;

const linkIcon = css`
  color: darkslategray;
  font-size: 48px;
  align-self: end;
  font-weight: normal;
  text-decoration: none;
  font-family: "Times New Roman", Times, serif;
  &:hover {
    text-decoration: underline;
  }
  &:focus {
    text-decoration: underline;
  }
`;

const ResumePage = ({ data }) => {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;
  return (
    <main>
      <h3 css={pageHeader}>{frontmatter.title}</h3>
      <div css={container}>
        <h1 css={name}>John Nevin</h1>
      </div>
      <span
        css={css`
          display: flex;
          flex-flow: row;
          justify-content: space-around;
          margin: 12px 20%;
        `}
      >
        <a
          css={linkIcon}
          href={frontmatter.github}
          target="_blank"
          rel="noreferrer"
        >
          {githubIcon}
        </a>
        <a
          css={linkIcon}
          href={frontmatter.link}
          target="_blank"
          rel="noreferrer"
        >
          {linkedinIcon}
        </a>
      </span>
      <div css={content} dangerouslySetInnerHTML={{ __html: html }} />
    </main>
  );
};

export default ResumePage;

export const Resume = graphql`
  query Resume {
    markdownRemark(fileAbsolutePath: { glob: "**/src/markdown/resume/*.md" }) {
      html
      frontmatter {
        slug
        title
        date
        link
        github
      }
    }
  }
`;
